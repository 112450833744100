import * as React from 'react'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import SeoHeaders from '../components/seo_headers'

const browser = (typeof window !== 'undefined') && window

const NotFoundPage = () => (browser && (
  <React.Fragment>
    <SeoHeaders
      title='No encontrado'
      description='No encontrado'
    />
    <Stack
      alignItems='center'
      justifyContent='center'
      sx={{ height: '100vh' }}
    >
      <Typography
        variant='h1'
      >
        404
      </Typography>
      <Typography
        variant='h4'
        component='h2'
      >
        No encontrado
      </Typography>
    </Stack>
  </React.Fragment>
))

export default NotFoundPage
